import { useRouter } from "next/router"

import { Login as Form } from "components/forms"

import { avoidAuth } from "lib/auth"
import { useTranslation } from "lib/i18n/translation"
import Gtm from "lib/gtm"

const Login = ({}) => {

	const router = useRouter()
	const url = router ? router.asPath : null
	const { c } = useTranslation("common")

	return (
		<>
			<Gtm pageType="Connexion" pagePath={url} pageTitle={c("connection")} />
			<h2>{c("login")}</h2>
			<Form />
		</>
	)
}

export default avoidAuth(Login)
